
/** 基礎方法 / 共通方法 */
import {Options, Vue} from 'vue-class-component';
/** 元件 */
import {ElRow, ElCol} from 'element-plus';
import Proxy from '@/web/operation/commission/proxy.vue';
import Chilren from '@/web/operation/commission/children.vue';
import Tips from '@/common/web/tips/index.vue';

/** service */
import {App, Format, Storage} from '@/services';
import {GetUser} from '@/model/tokenGetUser';

/** model */

@Options({
  components: {
    ElRow,
    ElCol,
    Proxy,
    Chilren,
    Tips
  }
})
export default class CommissionReport extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  upperCase = Format.upperCase;
  formatNumber = Format.formatNumber;
  userinfo = App.getUserinfo();
  info: {comp: string} = {comp: 'Proxy'};
  nowLang = localStorage.getItem('langrage');
  get rules() {
    const rulesMap = new Map([
      ['Proxy', ['ui_text_006']],
      ['Chilren', ['ui_text_006', 'ui_viewed_one_year']]
    ]);
    return rulesMap.get(this.info.comp);
  }
  async mounted() {
    await GetUser.action();
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    this.info.comp = this.userinfo?.levelId === '1' ? 'Proxy' : 'Chilren';
  }
  /**
   * router handler
   * name to route name
   */
  termClick(name: string) {
    this.$router.push({name});
  }
}
