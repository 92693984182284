<template>
  <div class="wrapper-center" v-loading="isLoading">
    <el-row
      gutter="10"
      type="flex"
      justify="start"
      align="bottom"
      class="u_m--t20 u_m--b20 background-day"
    >
      <el-col :span="6">
        <p class="u_fw--bold u_m--b5">{{ $t('rp_acc') }}</p>
        <el-input
          v-model="search.userAccount"
          @search="onSearch"
          :autofocus="true"
          background="none"
          left-icon=""
          class="opacity-search c_input--longest"
          :placeholder="$t('ui_please_enter_user_name_new')"
        >
          <template #prepend> <i class="el-icon-search"></i></template>
        </el-input>
      </el-col>
      <el-col :span="10" class="tab-wrapper">
        <p class=" u_fw--bold u_m--b5">{{ $t('ui_settlement_day') }}</p>
        <el-date-picker
          v-model="dateTimeRange"
          type="monthrange"
          :range-separator="$t('ui_to')"
          :start-placeholder="$t('ui_start_date')"
          :end-placeholder="$t('ui_end_date')"
          format="YYYY-MM"
          :disabled-date="disabledDate"
          :clearable="false"
          class="primary-picker c_input--longest"
        ></el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="small" class="main-btn u_w--100p" @click="onSearch">{{
          $t('ui_search_for')
        }}</el-button>
      </el-col>
    </el-row>
    <custom-table
      :titleList="titleList"
      :list="showList"
      :table="table"
      @click="handleTrClick"
      @change="handleChange"
      :pagination="pagination"
      :loading="tableLoading"
    >
      <template #profit>
        <span class="flex flex-row flex-center flex-justify-center">
          {{ $t('ui_text_008') }}
          <el-tooltip class="item" effect="light" :content="$t('ui_text_007')" placement="top">
            <i class="el-icon-question note_rule u_m--l5"></i>
          </el-tooltip>
        </span>
      </template>
    </custom-table>

    <el-dialog round class="background-button" v-model="showDetailInfo" :before-close="beforeClose">
      <el-row type="flex" align="center" justify="center">
        <span>{{ detail.title }}</span>
      </el-row>
      <custom-table
        :titleList="detail.table.title"
        :list="detail.showList"
        :table="detail.table"
        :pagination="detail.pagination"
        @change="handleDetailChange"
        :loading="detail.isLoading"
        style="margin-top: 10px;"
      >
        <template v-slot:default="{item: {value, key}}">
          <span
            v-if="key == 'lose'"
            :class="
              `
              text-${parseInt(value) > 0 ? 'tomato' : parseInt(value) < 0 ? 'grass' : 'night'}`
            "
            >{{ value }}</span
          >
        </template>
        <template #sum>
          <el-row
            class="text-night u_fw--bold background-button table-sum flex-center"
            type="flex"
            justify="space-between"
            align="center"
          >
            <el-col :span="8" :class="`u_t--center`">
              {{ $t('rp_sum') }}
            </el-col>
            <el-col :span="8" :class="`u_t--center`">
              {{ detail.calculate.totalReward }}
            </el-col>
            <el-col
              :span="8"
              :class="
                `u_t--center
              text-${
                parseInt(detail.calculate.totalLose) > 0
                  ? 'tomato'
                  : parseInt(detail.calculate.totalLose) < 0
                  ? 'grass'
                  : 'night'
              }`
              "
            >
              {{ detail.calculate.totalLose }}
            </el-col>
          </el-row>
        </template>
      </custom-table>
      <el-row type="flex" gutter="10" align="center" justify="center" class="summary">
        <el-col :span="12" class="dot">
          <span class="u_m--r10">{{ $t('ui_text_013') }}</span>
          <span
            :span="12"
            :class="
              `text-${
                parseInt(detail.calculate.totalLose) > 0
                  ? 'tomato'
                  : parseInt(detail.calculate.totalLose) < 0
                  ? 'grass'
                  : 'night'
              }`
            "
          >
            {{ getUnit('ui_yuan', detail.calculate.totalLose) }}
          </span>
        </el-col>
        <el-col :span="12" class="dot">
          <span class="u_m--r10">{{ $t('rp_total_amount') }}</span>
          <span
            :span="12"
            :class="
              `text-${
                parseInt(detail.profit) > 0
                  ? 'tomato'
                  : parseInt(detail.profit) < 0
                  ? 'grass'
                  : 'night'
              }`
            "
          >
            {{ getUnit('ui_yuan', detail.profit) }}
          </span>
        </el-col>
      </el-row>
      <el-row type="flex" gutter="10" align="center" justify="center" class="summary">
        <el-col :span="12" class="dot">
          <span class="u_m--r10">{{ $t('ui_platform_fee') }}</span>
          <span>
            {{ getUnit('ui_yuan', detail.platformAmount) }}
          </span>
        </el-col>
        <el-col :span="12" class="dot">
          <span class="u_m--r10">{{ $t('ui_bonus_rebate') }}</span>
          <span
            :class="
              `text-${
                parseInt(detail.activeAmount) > 0
                  ? 'tomato'
                  : parseInt(detail.activeAmount) < 0
                  ? 'grass'
                  : 'night'
              }`
            "
          >
            {{ getUnit('ui_yuan', detail.activeAmount) }}
          </span>
        </el-col>
      </el-row>

      <el-row type="flex" gutter="10" align="center" justify="center" class="summary">
        <el-col :span="24" class="dot">
          {{
            `${$t('ui_text_013')} - ${$t('ui_platform_fee')} - ${$t('ui_bonus_rebate')} = ${$t(
              'ui_text_008'
            )}`
          }}
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ElRow, ElCol, ElInput, ElButton, ElMessage, ElDialog, ElLoading} from 'element-plus';
import CustomTable from '@/common/web/table/index.vue';
import {App, Format, Storage} from '@/services';
import {
  ICommission,
  ICommissionDetailResult,
  ICommissionResult,
  ICommissionDetail,
  IPage
} from '@/model/commission';
import {commission} from '@/services/server/wap/commission';
import eventBus from '@/web/eventBus';

// const  Format.TimeHandle = Format. Format.TimeHandle;
const formatString = 'YYYY-MM';
@Options({
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElButton,
    ElDialog,
    ElLoading,
    CustomTable
  },

  mounted() {
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    this.onSearch();
    eventBus.bus$on('langChange', () => {
      this.onSearch(this.pagination.page);
    });
  },
  beforeDestroy() {
    eventBus.bus$off('langChange');
  },
  methods: {}
})
export default class Proxy extends Vue {
  config = App.getConfig() || {
    currency: {
      code: 'CNY',
      id: '0',
      text: '中文'
    },
    dollarSign: '¥'
  };
  upperCase = Format.upperCase;
  formatNumber = Format.formatNumber;
  userinfo = App.getUserinfo();
  isLoading = false;
  search = {
    userAccount: '',
    startMonth: '',
    endMonth: '',
    newTask: true
  };
  showDetailInfo = false;
  itemSearch = {
    newTask: true,
    isLoading: false,
    atdrId: ''
  };
  detail: {
    newTask: boolean;
    tableLoading: boolean;
    title: string;
    commissionRate: string;
    commissionBonus: string;
    profit: string;
    platformCommissionRate: string;
    platformAmount: string;
    activeAmount: string;
    lose: string;
    reward: string;
    table: {
      list: ICommissionDetail[];
      title: {
        name: string;
        key: string;
      }[];
      keys: string[];
    };
    showList: ICommissionDetail[];
    pagination: {
      page: number;
      totalPage: number;
      pageLimit: number;
      totalNumber: number;
    };
    calculate: {
      lose: string;
      pageLose: string;
      pageReward: string;
      reward: string;
      totalLose: string;
      totalReward: string;
    };
  } = {
    newTask: false,
    tableLoading: false,
    title: '',
    commissionRate: '',
    commissionBonus: '0',
    profit: '0',
    platformCommissionRate: '',
    platformAmount: '0',
    activeAmount: '0',
    lose: '0',
    reward: '',
    table: {
      list: [],
      title: [
        {
          name: 'rp_acc',
          key: 'userName'
        },
        {
          name: 'ui_bonus_rebate',
          key: 'reward'
        },
        {
          name: 'ui_text_016',
          key: 'lose'
        }
      ],
      keys: ['userName', 'reward', 'lose']
    },
    showList: [],
    pagination: {
      page: 0,
      totalPage: 1,
      pageLimit: 20,
      totalNumber: 0
    },
    calculate: {
      lose: '',
      pageLose: '',
      pageReward: '',
      reward: '',
      totalLose: '',
      totalReward: ''
    }
  };
  active = '';
  list: ICommission[] = [];
  showList: ICommission[] = [];
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  titleList = [
    {name: 'ui_settlement_day0', key: 'time'},
    {name: 'rp_username', key: 'userName'},
    {name: 'rp_level', key: 'levelText'},
    {name: 'ui_text_008', key: 'profit'},
    {name: 'ui_text_019', key: 'commissionRate'},
    {name: 'ui_text_020', key: 'commissionBonus'},
    {name: 'ui_text_021', key: 'platformCommissionRate'},
    {name: 'ui_text_022', key: 'platformCommissionBonus'},
    {name: 'ui_wap_text_035', key: 'rewardAt'},
    {name: 'ui_text_023', key: 'rewardBonus'}
  ];
  tableLoading = false;
  info: {showTips: boolean; comp: string} = {showTips: false, comp: 'Proxy'};
  nowLang = localStorage.getItem('langrage');
  start = new Date().setMonth(new Date().getMonth() - 2);
  end = new Date();
  dateTimeRange = [this.start, this.end]; // '00:00:00', '23:59:59'

  get table() {
    const list: ICommission[] = [];
    this.list.map(v => {
      v.linkDate = Format.TimeHandle.format(
        new Date(v.time.split(' - ')[0] || v.time),
        formatString
      );
      list.push(v);
    });
    return {
      list,
      keys: [
        'time',
        'userName',
        'levelText',
        'profit',
        'commissionRate',
        'commissionBonus',
        'platformCommissionRate',
        'platformCommissionBonus',
        'rewardAt',
        'rewardBonus'
      ]
    };
  }
  get loadmore() {
    return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
  }

  beforeClose(done: any) {
    // alert('beforeClose');
    console.log(done, 'done');
    this.showDetailInfo = false;
    done();
  }
  handleDate(val: (Date | number)[]) {
    console.log('val: ', val);
    const [startMonth, endMonth] = val;
    console.log(typeof endMonth, typeof startMonth);
    this.search.startMonth = Format.formatYM(new Date(startMonth));
    this.search.endMonth = Format.formatYM(new Date(endMonth));
  }
  handleDetailChange(item: {limit: number; page: number}) {
    console.log('item: ', item);
    this.detail.pagination.pageLimit = item.limit;
    this.handleGetDatail(item.page);
  }
  handleChange(item: {limit: number; page: number}) {
    console.log('handleChange item: ', item);
    this.pagination.pageLimit = item.limit;
    this.onSearch(item.page);
  }
  async onSearch(val: {type?: string} | number | undefined | string) {
    console.log('onSearch val: ', val, typeof val, this.pagination);
    this.tableLoading = true;
    if (!val || (typeof val === 'object' && val['type']) || typeof val === 'string') {
      this.search.newTask = true;
      this.showList = [];
    }
    if (!val) {
      this.pagination.page = 0;
    } else {
      this.pagination.page = +val;
    }

    this.isLoading = true;
    //dosearch
    this.handleDate(this.dateTimeRange);
    const params = {
      page: this.pagination.page,
      page_limit: this.pagination.pageLimit,
      user_account: this.search.userAccount,
      start_month: this.search.startMonth,
      end_month: this.search.endMonth,
      device: 4
    };
    console.log({params});
    const data = await commission.read<ICommissionResult>(params);
    if (data instanceof Error) {
      ElMessage({
        type: 'error',
        message: data.message
      });
    } else {
      console.log(data, 'data');
      this.list = data.list;
      this.pagination = data.pagination;
      this.showList = [...this.list];
    }
    setTimeout(() => {
      this.isLoading = false;
      this.tableLoading = false;
    });
  }
  async handleTrClick(item: {
    levelId: number;
    time: string;
    commissionRate: string;
    commissionBonus: string;
    profit: string;
    platformCommissionRate: string;
    atdrId: string;
    startAt: string;
    endAt: string;
    type?: string;
  }) {
    console.log(item, 'handleTrClickitem');
    // this.show = true
    if (item.levelId === 1) {
      this.itemSearch.atdrId = item.atdrId;
      this.detail = {
        ...this.detail,
        tableLoading: true,
        title: `${item.startAt} ~ ${item.endAt}`,
        commissionRate: item.commissionRate,
        commissionBonus: item.commissionBonus,
        profit: item.profit,
        platformCommissionRate: item.platformCommissionRate
      };
      await this.handleGetDatail(undefined);
    }
  }
  async handleGetDatail(val: {type: string} | number | undefined) {
    console.log('handleGetDatail val: ', val, typeof val);
    this.itemSearch.isLoading = true;
    if (!val || (typeof val === 'object' && val['type'])) {
      this.detail.newTask = true;
      this.detail.showList = [];
    }
    if (!val) {
      this.detail.pagination.page = 1;
    } else {
      this.detail.pagination.page = +val;
    }
    //dosearch
    const params = {
      page: this.detail.pagination.page,
      page_limit: this.detail.pagination.pageLimit,
      atdr_id: this.itemSearch.atdrId
    };
    setTimeout(() => {
      this.itemSearch.isLoading = false;
    });
    const res = await commission.detail<ICommissionDetailResult>(params);
    console.log('res: ', res);
    if (res instanceof Error) {
      ElMessage({
        type: 'error',
        message: res.message
      });
    } else {
      this.detail = {
        ...this.detail,
        calculate: res.calculate,
        platformAmount: res.platformAmount,
        activeAmount: res.activeAmount,
        reward: res.reward,
        lose: res.lose,
        table: {
          ...this.detail.table,
          list: res.list || []
        },
        pagination: res.pagination,
        showList: res.list
      };
      this.showDetailInfo = true;
    }
  }

  disabledDate = (time: Date) => {
    // time.getTime() >= beginDateVal false 可用，true 不可用
    const end = new Date();
    const start = new Date();
    start.setMonth(start.getMonth() - 2);
    return time.getTime() > end.getTime() || time.getTime() < start.getTime(); //今天及以后的日期都不能选
  };
  /**
   * router handler
   * name to route name
   */
  termClick(name: string) {
    this.$router.push({name});
  }

  unitMap = new Map([
    ['USD', 'ui_dollar_usd'],
    ['VND', 'ui_dollar_vnd'],
    ['THB', 'ui_dollar_thb'],
    ['CNY', 'ui_dollar_cny']
  ]);

  get dollarUnit() {
    const code = this.config.currency ? this.config.currency.code : 'CNY';
    return this.unitMap.get(code);
  }

  getUnit(key: string, val: string | number) {
    return `${this.$t(key, [val])} ${this.$t(key, {
      dollar: this.$t(this.dollarUnit || 'ui_dollar_cny')
    })}`;
  }
}
</script>
<style lang="scss" scoped>
.loading {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background-color: white;
  top: 45%;
  left: calc(50% - 50px);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px /* 1/25 */ 1px /* 1/25 */ 10px /* 10/25 */ 1px /* 1/25 */ rgba(211, 216, 224, 1);
}
.tab-wrapper {
  margin-top: 14px /* 14/25 */;
}
.text-day {
  color: $cf;
}
.text-gold {
  color: $gold;
}
.text-darkgray {
  color: $c9;
}
.background-primary {
  background-color: $bg;
}
.background-day {
  background-color: $cf;
}
.wrapper-center {
  height: 100%;
  font-size: 16px /* 16/25 */;
}
.opacity-search {
  background: #f6f7f8;
  border-radius: 8px /* 8/25 */;
}
.tab-wrapper :deep(.el-range-editor.el-input__inner) {
  width: 100% !important;
}
.u_w--100p {
  width: 100%;
}
.main-btn {
  // width: calc(100% - 0.96rem /* 24/25 */);
  height: 40px /* 32/25 */;
  color: $cf;
  background-color: $bg;
  border-radius: 8px /* 8/25 */;
  font-size: 16px;
  border-width: 0;
}
.u_t--center {
  text-align: center;
}
.load-more {
  @extend .flex;
  @extend .flex-center;
  @extend .flex-justify-center;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  color: #333333;
  @extend .clickAction;
}

.el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.el-input__inner {
  background-color: #f5f5f5;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
[class*='c_input'] {
  border-radius: 5px;
}
[class*='c_input'] button,
[class*='c_input'] > div,
[class*='c_input'] input,
[class*='c_input'] select,
[class*='c_input'] textarea,
[class*='c_input'] .c_input__inner {
  border-radius: inherit;
  width: inherit;
}
.u_m--b5 {
  margin-bottom: 5px;
}
.u_m--t20 {
  margin-top: 20px;
}
.u_m--b20 {
  margin-bottom: 20px;
}
.table-sum {
  height: 48px;
}
.summary {
  margin: 0 20px !important;
  :deep(> .el-col) {
    display: flex !important;
    align-items: center;
    height: 40px;
    line-height: 40px;
  }
}
.dot::before {
  content: '\2022';
  font-size: 30px;
  color: #007aff;
  margin: -2px 5px -2px 10px;
}
.note_rule {
  margin-left: 5px;
  line-height: 24px;
  color: #ff0000;
  display: flex;
  flex-direction: column;
}

.u_m--r10 {
  margin-right: 10px;
}

:deep(.el-date-editor .el-range-separator) {
  width: 72px;
  line-height: 32px;
}
</style>
