
import {Options, Vue} from 'vue-class-component';
import {ElRow, ElCol, ElInput, ElButton, ElMessage, ElLoading} from 'element-plus';
import CustomTable from '@/common/web/table/index.vue';
import {App, Format, Storage} from '@/services';
import {ICommissionDetailResult, ICommissionResult, IPage} from '@/model/commission';
import {commission} from '@/services/server/wap/commission';
import eventBus from '@/web/eventBus';

// const  Format.TimeHandle = Format. Format.TimeHandle;

const formatString = 'YYYY-MM';
@Options({
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElButton,
    ElLoading,
    CustomTable
  },

  mounted() {
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    this.onSearch();
    eventBus.bus$on('langChange', () => {
      this.onSearch(this.pagination.page);
    });
  },
  beforeDestroy() {
    eventBus.bus$off('langChange');
  },
  watch: {
    show(val) {
      if (!val) {
        this.detail.pagination = {
          page: 0,
          pageLimit: 20,
          totalPage: 1
        };
      }
    }
  },
  methods: {
    handleDetailChange(item: {limit: number; page: number}) {
      this.detail.pagination.pageLimit = item.limit;
      this.handleGetDatail(item.page);
    },
    handleChange(item: {limit: number; page: number}) {
      console.log('handleChange item: ', item);
      this.pagination.pageLimit = item.limit;
      this.onSearch(item.page);
    },
    async onSearch(val: {type: string} | number | undefined) {
      console.log('onSearch val: ', val, typeof val, this.pagination);
      if (!val || (typeof val === 'object' && val['type']) || typeof val === 'string') {
        this.search.newTask = true;
        this.showList = [];
      }
      if (!val) {
        this.pagination.page = 0;
      } else {
        this.pagination.page = val;
      }

      this.tableLoading = true;
      this.pageLoading = true;
      //dosearch
      const params = {
        page: this.pagination.page,
        page_limit: this.pagination.pageLimit
      };
      // await this.getList(this.$snakeCase(params));
      const data = await commission.read<ICommissionResult>(params);
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message: data.message
        });
      } else {
        console.log(data, 'data');
        this.list = data.list;
        this.pagination = data.pagination;
        this.showList = [...this.list];
        console.log(this.showList, 'this.showList');
      }
      setTimeout(() => {
        this.isLoading = false;
        this.pageLoading = false;
        this.tableLoading = false;
      });
    },
    async handleTrClick(item: {
      levelId: number;
      time: string;
      commissionRate: string;
      commissionBonus: string;
      profit: string;
      platformCommissionRate: string;
      atdrId: string;
    }) {
      console.log(item, 'handleTrClickitem');
      // this.show = true
      this.itemSearch.atdrId = item.atdrId;
      this.detail = {
        ...this.detail,
        tableLoading: true,
        title: item.time,
        commissionRate: item.commissionRate,
        commissionBonus: item.commissionBonus,
        profit: item.profit,
        platformCommissionRate: item.platformCommissionRate
      };
      this.detail.isLoading = true;
      await this.handleGetDatail();
    },
    async handleGetDatail(val: {type: string} | number | undefined) {
      console.log('handleGetDatail val: ', val, typeof val);
      this.itemSearch.isLoading = true;
      console.log(this.detail, 'this.detail');

      if (!val || (typeof val === 'object' && val['type'])) {
        this.detail.newTask = true;
        this.detail.showList = [];
      }

      if (!val) {
        this.detail.pagination.page = 1;
      } else {
        this.detail.pagination.page = val;
      }
      //dosearch
      const params = {
        page: this.detail.pagination.page,
        page_limit: this.detail.pagination.pageLimit,
        atdr_id: this.itemSearch.atdrId
      };
      setTimeout(() => {
        this.itemSearch.isLoading = false;
        this.detail.isLoading = false;
      });
      const res = await commission.detail<ICommissionDetailResult>(params);
      if (res instanceof Error) {
        ElMessage({
          message: res.message
        });
      } else {
        this.detail = {
          ...this.detail,
          calculate: res.calculate,
          platformAmount: res.platformAmount,
          activeAmount: res.activeAmount,
          lose: res.lose,
          reward: res.reward,
          table: {
            ...this.detail.table,
            list: res.list || []
          },
          pagination: res.pagination,
          showList: res.list
        };
      }
      this.show = true;
    }
  },
  computed: {
    table() {
      const list: Array<{
        linkDate?: string;
        userName: string;
        levelText: string;
        time: string;
        rewardBonus: string;
        rewardAt: string;
      }> = [];
      this.list.map(
        (v: {
          linkDate?: string;
          time: string;
          userName: string;
          levelText: string;
          rewardBonus: string;
          rewardAt: string;
        }) => {
          v.linkDate = Format.TimeHandle.format(new Date(v.time), formatString);
          list.push(v);
        }
      );
      return {
        list,
        keys: [
          'time',
          'profit',
          'commissionRate',
          'commissionBonus',
          'rewardAt',
          'rewardBonus',
          'detail'
        ]
      };
    },
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit <= this.pagination.totalNumber;
    },
    detailloadmore() {
      return (
        this.detail.pagination.page * this.detail.pagination.pageLimit <=
        this.detail.pagination.totalNumber
      );
    }
  }
})
export default class Children extends Vue {
  name = 'Chilren';
  config = App.getConfig() || {
    currency: {
      code: 'CNY',
      id: '0',
      text: '中文'
    },
    dollarSign: '¥'
  };
  upperCase = Format.upperCase;
  formatNumber = Format.formatNumber;
  userinfo = App.getUserinfo();
  isLoading = false;
  search = {
    userAccount: '',
    startMonth: '',
    endMonth: '',
    newTask: true
  };
  show = false;
  itemSearch = {
    newTask: true,
    isLoading: false,
    atdrId: ''
  };
  detail = {
    title: '',
    commissionRate: '',
    commissionBonus: 0,
    profit: 0,
    platformCommissionRate: '',
    platformAmount: 0,
    activeAmount: 0,
    lose: 0,
    reward: 0,
    table: {
      list: [],
      title: [
        {
          name: 'rp_acc',
          key: 'userName'
        },
        {
          name: 'ui_bonus_rebate',
          key: 'reward'
        },
        {
          name: 'ui_text_016',
          key: 'lose'
        }
      ],
      keys: ['userName', 'reward', 'lose']
    },
    showList: [],
    pagination: {
      page: 0,
      totalPage: 1,
      pageLimit: 20,
      totalNumber: 0
    },
    isLoading: false,
    tableLoading: false
  };
  list = [];
  showList = [];
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  titleList = [
    {name: 'ui_settlement_day0', key: 'time'},
    {name: 'ui_text_008', key: 'profit'},
    {name: 'ui_refund_ratio', key: 'commissionRate'},
    {name: 'ui_text_020', key: 'commissionBonus'},
    {name: 'ui_wap_text_035', key: 'rewardAt'},
    {name: 'ui_text_023', key: 'rewardBonus'},
    {name: 'ui_detail', key: 'detailTh'}
  ];
  tableLoading = false;
  pageLoading = false;
  nowLang = localStorage.getItem('language');
  unitMap = new Map([
    ['USD', 'ui_dollar_usd'],
    ['VND', 'ui_dollar_vnd'],
    ['THB', 'ui_dollar_thb'],
    ['CNY', 'ui_dollar_cny']
  ]);
  /**
   * router handler
   * name to route name
   */
  termClick(name: string) {
    this.$router.push({name});
  }
  get dollarUnit() {
    const code = this.config.currency ? this.config.currency.code : 'CNY';
    return this.unitMap.get(code);
  }

  getUnit(key: string, val: string | number) {
    return `${this.$t(key, [val])} ${this.$t(key, {
      dollar: this.$t(this.dollarUnit || 'ui_dollar_cny')
    })}`;
  }
}
