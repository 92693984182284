
import {Options, Vue} from 'vue-class-component';
import {ElRow, ElCol, ElInput, ElButton, ElMessage, ElDialog, ElLoading} from 'element-plus';
import CustomTable from '@/common/web/table/index.vue';
import {App, Format, Storage} from '@/services';
import {
  ICommission,
  ICommissionDetailResult,
  ICommissionResult,
  ICommissionDetail,
  IPage
} from '@/model/commission';
import {commission} from '@/services/server/wap/commission';
import eventBus from '@/web/eventBus';

// const  Format.TimeHandle = Format. Format.TimeHandle;
const formatString = 'YYYY-MM';
@Options({
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElButton,
    ElDialog,
    ElLoading,
    CustomTable
  },

  mounted() {
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    this.onSearch();
    eventBus.bus$on('langChange', () => {
      this.onSearch(this.pagination.page);
    });
  },
  beforeDestroy() {
    eventBus.bus$off('langChange');
  },
  methods: {}
})
export default class Proxy extends Vue {
  config = App.getConfig() || {
    currency: {
      code: 'CNY',
      id: '0',
      text: '中文'
    },
    dollarSign: '¥'
  };
  upperCase = Format.upperCase;
  formatNumber = Format.formatNumber;
  userinfo = App.getUserinfo();
  isLoading = false;
  search = {
    userAccount: '',
    startMonth: '',
    endMonth: '',
    newTask: true
  };
  showDetailInfo = false;
  itemSearch = {
    newTask: true,
    isLoading: false,
    atdrId: ''
  };
  detail: {
    newTask: boolean;
    tableLoading: boolean;
    title: string;
    commissionRate: string;
    commissionBonus: string;
    profit: string;
    platformCommissionRate: string;
    platformAmount: string;
    activeAmount: string;
    lose: string;
    reward: string;
    table: {
      list: ICommissionDetail[];
      title: {
        name: string;
        key: string;
      }[];
      keys: string[];
    };
    showList: ICommissionDetail[];
    pagination: {
      page: number;
      totalPage: number;
      pageLimit: number;
      totalNumber: number;
    };
    calculate: {
      lose: string;
      pageLose: string;
      pageReward: string;
      reward: string;
      totalLose: string;
      totalReward: string;
    };
  } = {
    newTask: false,
    tableLoading: false,
    title: '',
    commissionRate: '',
    commissionBonus: '0',
    profit: '0',
    platformCommissionRate: '',
    platformAmount: '0',
    activeAmount: '0',
    lose: '0',
    reward: '',
    table: {
      list: [],
      title: [
        {
          name: 'rp_acc',
          key: 'userName'
        },
        {
          name: 'ui_bonus_rebate',
          key: 'reward'
        },
        {
          name: 'ui_text_016',
          key: 'lose'
        }
      ],
      keys: ['userName', 'reward', 'lose']
    },
    showList: [],
    pagination: {
      page: 0,
      totalPage: 1,
      pageLimit: 20,
      totalNumber: 0
    },
    calculate: {
      lose: '',
      pageLose: '',
      pageReward: '',
      reward: '',
      totalLose: '',
      totalReward: ''
    }
  };
  active = '';
  list: ICommission[] = [];
  showList: ICommission[] = [];
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  titleList = [
    {name: 'ui_settlement_day0', key: 'time'},
    {name: 'rp_username', key: 'userName'},
    {name: 'rp_level', key: 'levelText'},
    {name: 'ui_text_008', key: 'profit'},
    {name: 'ui_text_019', key: 'commissionRate'},
    {name: 'ui_text_020', key: 'commissionBonus'},
    {name: 'ui_text_021', key: 'platformCommissionRate'},
    {name: 'ui_text_022', key: 'platformCommissionBonus'},
    {name: 'ui_wap_text_035', key: 'rewardAt'},
    {name: 'ui_text_023', key: 'rewardBonus'}
  ];
  tableLoading = false;
  info: {showTips: boolean; comp: string} = {showTips: false, comp: 'Proxy'};
  nowLang = localStorage.getItem('langrage');
  start = new Date().setMonth(new Date().getMonth() - 2);
  end = new Date();
  dateTimeRange = [this.start, this.end]; // '00:00:00', '23:59:59'

  get table() {
    const list: ICommission[] = [];
    this.list.map(v => {
      v.linkDate = Format.TimeHandle.format(
        new Date(v.time.split(' - ')[0] || v.time),
        formatString
      );
      list.push(v);
    });
    return {
      list,
      keys: [
        'time',
        'userName',
        'levelText',
        'profit',
        'commissionRate',
        'commissionBonus',
        'platformCommissionRate',
        'platformCommissionBonus',
        'rewardAt',
        'rewardBonus'
      ]
    };
  }
  get loadmore() {
    return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
  }

  beforeClose(done: any) {
    // alert('beforeClose');
    console.log(done, 'done');
    this.showDetailInfo = false;
    done();
  }
  handleDate(val: (Date | number)[]) {
    console.log('val: ', val);
    const [startMonth, endMonth] = val;
    console.log(typeof endMonth, typeof startMonth);
    this.search.startMonth = Format.formatYM(new Date(startMonth));
    this.search.endMonth = Format.formatYM(new Date(endMonth));
  }
  handleDetailChange(item: {limit: number; page: number}) {
    console.log('item: ', item);
    this.detail.pagination.pageLimit = item.limit;
    this.handleGetDatail(item.page);
  }
  handleChange(item: {limit: number; page: number}) {
    console.log('handleChange item: ', item);
    this.pagination.pageLimit = item.limit;
    this.onSearch(item.page);
  }
  async onSearch(val: {type?: string} | number | undefined | string) {
    console.log('onSearch val: ', val, typeof val, this.pagination);
    this.tableLoading = true;
    if (!val || (typeof val === 'object' && val['type']) || typeof val === 'string') {
      this.search.newTask = true;
      this.showList = [];
    }
    if (!val) {
      this.pagination.page = 0;
    } else {
      this.pagination.page = +val;
    }

    this.isLoading = true;
    //dosearch
    this.handleDate(this.dateTimeRange);
    const params = {
      page: this.pagination.page,
      page_limit: this.pagination.pageLimit,
      user_account: this.search.userAccount,
      start_month: this.search.startMonth,
      end_month: this.search.endMonth,
      device: 4
    };
    console.log({params});
    const data = await commission.read<ICommissionResult>(params);
    if (data instanceof Error) {
      ElMessage({
        type: 'error',
        message: data.message
      });
    } else {
      console.log(data, 'data');
      this.list = data.list;
      this.pagination = data.pagination;
      this.showList = [...this.list];
    }
    setTimeout(() => {
      this.isLoading = false;
      this.tableLoading = false;
    });
  }
  async handleTrClick(item: {
    levelId: number;
    time: string;
    commissionRate: string;
    commissionBonus: string;
    profit: string;
    platformCommissionRate: string;
    atdrId: string;
    startAt: string;
    endAt: string;
    type?: string;
  }) {
    console.log(item, 'handleTrClickitem');
    // this.show = true
    if (item.levelId === 1) {
      this.itemSearch.atdrId = item.atdrId;
      this.detail = {
        ...this.detail,
        tableLoading: true,
        title: `${item.startAt} ~ ${item.endAt}`,
        commissionRate: item.commissionRate,
        commissionBonus: item.commissionBonus,
        profit: item.profit,
        platformCommissionRate: item.platformCommissionRate
      };
      await this.handleGetDatail(undefined);
    }
  }
  async handleGetDatail(val: {type: string} | number | undefined) {
    console.log('handleGetDatail val: ', val, typeof val);
    this.itemSearch.isLoading = true;
    if (!val || (typeof val === 'object' && val['type'])) {
      this.detail.newTask = true;
      this.detail.showList = [];
    }
    if (!val) {
      this.detail.pagination.page = 1;
    } else {
      this.detail.pagination.page = +val;
    }
    //dosearch
    const params = {
      page: this.detail.pagination.page,
      page_limit: this.detail.pagination.pageLimit,
      atdr_id: this.itemSearch.atdrId
    };
    setTimeout(() => {
      this.itemSearch.isLoading = false;
    });
    const res = await commission.detail<ICommissionDetailResult>(params);
    console.log('res: ', res);
    if (res instanceof Error) {
      ElMessage({
        type: 'error',
        message: res.message
      });
    } else {
      this.detail = {
        ...this.detail,
        calculate: res.calculate,
        platformAmount: res.platformAmount,
        activeAmount: res.activeAmount,
        reward: res.reward,
        lose: res.lose,
        table: {
          ...this.detail.table,
          list: res.list || []
        },
        pagination: res.pagination,
        showList: res.list
      };
      this.showDetailInfo = true;
    }
  }

  disabledDate = (time: Date) => {
    // time.getTime() >= beginDateVal false 可用，true 不可用
    const end = new Date();
    const start = new Date();
    start.setMonth(start.getMonth() - 2);
    return time.getTime() > end.getTime() || time.getTime() < start.getTime(); //今天及以后的日期都不能选
  };
  /**
   * router handler
   * name to route name
   */
  termClick(name: string) {
    this.$router.push({name});
  }

  unitMap = new Map([
    ['USD', 'ui_dollar_usd'],
    ['VND', 'ui_dollar_vnd'],
    ['THB', 'ui_dollar_thb'],
    ['CNY', 'ui_dollar_cny']
  ]);

  get dollarUnit() {
    const code = this.config.currency ? this.config.currency.code : 'CNY';
    return this.unitMap.get(code);
  }

  getUnit(key: string, val: string | number) {
    return `${this.$t(key, [val])} ${this.$t(key, {
      dollar: this.$t(this.dollarUnit || 'ui_dollar_cny')
    })}`;
  }
}
