import doFetch from '../../fetch';
const AGENTPATH = '/api/agent';

/** 36.4.1 取得傭金報表(退傭 總代/子代模式) (https://docs.google.com/document/d/1T5G_1j0hUUlXm2RHISoqxtc21iWFoeHu03Zq-G28PRs/edit#heading=h.7qxwq08eh4ll)
     * {
            "start_month": String,
            "end_month": String,
            "user_account": String,
            "page": Integer,
            "page_limit": Integer,
            "lang": String,
            "platform_code": String
        }

        */
//  read: ApiProxy('commission/get_commission_agent_report'),
/**
                * 36.4.2 取得傭金報表詳細資訊(退傭 總代/子代模式) (https://docs.google.com/document/d/1T5G_1j0hUUlXm2RHISoqxtc21iWFoeHu03Zq-G28PRs/edit#heading=h.ljt64tf0n17v)
                *
                   {
                       "atdr_id": String,
                       "page": Integer,
                       "page_limit": Integer,
                       "lang": String,
                       "platform_code": String
                   }
                */
//  detail: {
//      read: ApiProxy('commission/get_commission_agent_report_detail'),
//  },
/**
 *  user card info
 * @param data
 */
const actions = {
  read: async <T>(data: object) =>
    await doFetch<T>(`${AGENTPATH}/v1/commission/get_commission_agent_report`, 'POST', data),
  detail: async <T>(data: object) =>
    await doFetch<T>(`${AGENTPATH}/v1/commission/get_commission_agent_report_detail`, 'POST', data)
};

export const commission = actions;
